// Assets
import { useState } from "react";
import rightArrow from "../../../Assets/NewAssets/arrow1.png";
import leftArrow from "../../../Assets/NewAssets/arrow2.png";
import ProductCard from "../ProductCard/ProductCard";
import { useSwipeable } from "react-swipeable";

const ProductCarousal = ({
  products,
  ShowModal,
  setSelectedProduct,
  screenWidth,
}) => {
  const [index, setIndex] = useState(0);
  const [productIndex, setProductIndex] = useState(0);

  const handleIndex = (index) => {
    if (index >= products.length) {
      setIndex(0);
    } else if (index < 0) {
      setIndex(products.length - 1);
    } else {
      setIndex(index);
    }
    setProductIndex(0);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleIndex(index + 1),
    onSwipedRight: () => handleIndex(index - 1),
  });

  return screenWidth < 1200 ? (
    <div {...handlers} className="w-[100%] relative">
      <div className="relative h-[100%]">
        {/* <img
          src={rightArrow}
          onClick={() => handleIndex(index + 1)}
          alt="rightArrow"
          className="z-10 cursor-pointer absolute h-[5%] rounded-[50%] top-[47%] right-[2%]"
        />
        <img
          onClick={() => handleIndex(index - 1)}
          alt="lefttArrow"
          src={leftArrow}
          className="z-10 cursor-pointer absolute h-[5%] rounded-[50%] top-[47%] left-[2%]"
        /> */}

        <ProductCard
          productIndexProp={productIndex}
          setProductIndex={setProductIndex}
          information={products[index]}
          ShowModal={ShowModal}
          setSelectedProduct={setSelectedProduct}
          screenWidth={screenWidth}
        />
      </div>
    </div>
  ) : (
    <div className="sm:w-[100%] sm:h-[90%] sm:relative">
      {index < products.length - 1 ? (
        <img
          onClick={() => handleIndex(index + 1)}
          src={rightArrow}
          alt="rightArrow"
          className="z-10 cursor-pointer absolute h-[10%] rounded-[50%] top-[50%] right-[-2%]"
        />
      ) : (
        ""
      )}

      {index > 0 ? (
        <img
          onClick={() => handleIndex(index - 1)}
          src={leftArrow}
          alt="lefttArrow"
          className="z-10 cursor-pointer absolute h-[10%] rounded-[50%] top-[50%] left-[-2%]"
        />
      ) : (
        ""
      )}

      <ProductCard
        index={index}
        information={products[index]}
        ShowModal={ShowModal}
        setSelectedProduct={setSelectedProduct}
        screenWidth={screenWidth}
      />
    </div>
  );
};

export default ProductCarousal;
