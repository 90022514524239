import "./AboutPage.scss";

// Components
import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";
import DisplayDiv from "../../NewComponents/AboutPageComponent/DisplayDiv/DisplayDiv";

//Assets
// import img1 from "../../Assets/NewAssets/about/img1.png";
// import img2 from "../../Assets/NewAssets/about/img2.png";
// import img3 from "../../Assets/NewAssets/about/img3.png";
// import img4 from "../../Assets/NewAssets/about/img4.png";

// Content
import {
  procurement,
  partners,
  merchant,
  consultant,
  details_Procurement,
  details_Partners,
  details_Merchant,
  details_consultancy,
} from "../../Content/aboutPageContent";

import img1 from "../../Assets/NewAssets/NewPics/1.jpg";
import img2 from "../../Assets/NewAssets/NewPics/2.jpg";
import img3 from "../../Assets/NewAssets/NewPics/3.jpg";
import img4 from "../../Assets/NewAssets/NewPics/4.jpg";

const AboutPage = ({ screenWidth }) => {
  return screenWidth < 1200 ? (
    <div className="AboutPageContainer">
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      {/*Body  */}

      <div className="sm:bg-black w-[100%] h-[50px] mt-[2%] flex items-center justify-center">
        <div className="text-white font-[16px] bg-gradient-to-r from-[#377440] to-[#0C391B] h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
      </div>

      <div className="h-[750px] mt-[5%]">
        <div className="flex flex-col justify-center items-center h-[50%]">
          <div className="h-[50%] w-[100%]">
            <DisplayDiv
              src={img1}
              alt="img1"
              textHeading={partners}
              text={details_Partners}
              alternate={true}
              textColor="#FFE9BE"
              backgroundColor="#377440"
            />
          </div>

          <div className="h-[50%] w-[100%]">
            <DisplayDiv
              src={img2}
              alt="img2"
              textHeading={merchant}
              text={details_Merchant}
              alternate={false}
              backgroundColor="#FFE9BE"
              textColor="#377440"
            />
          </div>
        </div>
        {/* Seperator */}

        <div className="flex flex-col justify-center items-center h-[50%]">
          <div className="h-[50%] w-[100%]">
            <DisplayDiv
              src={img3}
              alt="img3"
              textHeading={consultant}
              text={details_consultancy}
              alternate={true}
              textColor="#FFE9BE"
              backgroundColor="#377440"
            />
          </div>

          <div className="h-[50%] w-[100%]">
            <DisplayDiv
              src={img4}
              alt="img4"
              textHeading={procurement}
              text={details_Procurement}
              alternate={false}
              backgroundColor="#FFE9BE"
              textColor="#377440"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="HomePageContainer sm:h-[100vh]">
      <div>
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="w-[100%] h-[8%] mt-[1.7%] flex items-center justify-center">
        <div className="sm:bg-black text-white font-[16px] h-[60%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
      </div>

      <div className="w-[100%] h-[82%] flex flex-col justify-center items-center">
        <div className="w-[90%] h-[95%]">
          <div className="flex flex-row h-[50%] w-[100%]">
            <DisplayDiv
              src={img1}
              alt="img1"
              textHeading={partners}
              text={details_Partners}
              alternate={true}
              textColor="#FFE9BE"
              backgroundColor="#377440"
            />
            <DisplayDiv
              src={img2}
              alt="img2"
              textHeading={merchant}
              text={details_Merchant}
              alternate={true}
              backgroundColor="#FFE9BE"
              textColor="#377440"
            />
          </div>
          <div className="flex flex-row h-[50%] w-[100%]">
            <DisplayDiv
              src={img4}
              alt="img4"
              textHeading={procurement}
              text={details_Procurement}
              alternate={true}
              backgroundColor="#FFE9BE"
              textColor="#377440"
            />
            <DisplayDiv
              src={img3}
              alt="img3"
              textHeading={consultant}
              text={details_consultancy}
              alternate={true}
              textColor="#FFE9BE"
              backgroundColor="#377440"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
