import "./ProductPage.scss";

import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";
import { productInformation } from "../../Content/products";

// //Assets Main Images
// import mainImage1 from "../../Assets/NewAssets/products/main1.png";
// import mainImage2 from "../../Assets/NewAssets/products/main2.png";
// import mainImage3 from "../../Assets/NewAssets/products/main3.png";

// // Special Products
// import ghee from "../../Assets/NewAssets/products/specialProducts/Ghee.png";
// import milltes from "../../Assets/NewAssets/products/specialProducts/Millets.png";
// import pershable from "../../Assets/NewAssets/products/specialProducts/Parishables.png";

// // Pulses
// import pulses from "../../Assets/NewAssets/products/pulses/pulses.png";
// import kabuliChana from "../../Assets/NewAssets/products/pulses/kabuliChana.png";

// //Spices
// import turmericPowder from "../../Assets/NewAssets/products/spices/tumeric.png";
// import corriander from "../../Assets/NewAssets/products/spices/coriander.png";
// import chilliPowder from "../../Assets/NewAssets/products/spices/chilliPowder.png";

import ProductCarousal from "../../NewComponents/ProductPageComponent/ProductCarousal/ProductCarousal";
import { useState } from "react";
import ProductModal from "../../NewComponents/ProductPageComponent/ProductModal/ProductModal";

const ProductPage = ({ screenWidth }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const showModalHandler = () => {
    setShowModal(!showModal);
  };

  // const productInformation = [
  //   {
  //     id: 1,
  //     name: "Special Products",
  //     img: mainImage1,
  //     productInformation:
  //       "We procure fresh materials as per buyers requirement of specifications and also keep stocks for upcoming  export shipments",
  //     information: "We export these special product to the Middle east region.",
  //     product: [
  //       {
  //         id: 1.1,
  //         name: "A2 Gir cow ghee",
  //         available: true,
  //         MinQuantity: "As per requirement",
  //         PackagingType: "Retail packs and Bulk packs available",
  //         Variety: "A2 Premium Gir cow ghee",
  //         img: ghee,
  //       },
  //       {
  //         id: 1.2,
  //         name: "Millets",
  //         available: true,
  //         MinQuantity: "As per requirement",
  //         PackagingType: "Retail packs and Bulk packs available",
  //         Variety:
  //           "Finger millet, foxtail millet, proso millet and barnyard millet",
  //         img: milltes,
  //       },
  //       {
  //         id: 1.3,
  //         name: "Pershable",
  //         available: true,
  //         MinQuantity: "As per requirement",
  //         PackagingType: "Mesh packs / as per client requirement",
  //         Variety: "Onion / Garlic / Ginger / Potato ",
  //         img: pershable,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Pulses",
  //     img: mainImage3,
  //     productInformation:
  //       "We are connected with different mills and stockiest to  provide best quality of pulses and lentils from Central India, Rajasthan and other states as per buyers requirement. Leveraging our connections with different mills and stockiest, we guarantee the supply of premium-quality pulses and lentils sourced from Central India, Rajasthan, and other states, precisely meeting the demand of our buyers.",
  //     information:
  //       "We provide the world with high quality, protein packed pulses that embody the essence of India’s agricultural excellence.",
  //     product: [
  //       {
  //         id: 2.1,
  //         name: "Pulses",
  //         available: true,
  //         MinQuantity: "As per requirement",
  //         PackagingType:
  //           "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
  //         Variety:
  //           "White, Yellow and green gram split chic peas split kidney beans etc",
  //         img: pulses,
  //       },
  //       {
  //         id: 2.2,
  //         name: "Desi & Kabuli Chickpea",
  //         available: true,
  //         MinQuantity: "As per requirement",
  //         PackagingType:
  //           "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
  //         Variety:
  //           "42/44 Counts per Ounce (12mm) 44/46 Counts per Ounce (11mm) 58/60 Counts per Ounce (8-9mm)",
  //         img: kabuliChana,
  //       },
  //     ],
  //   },

  //   {
  //     id: 3,
  //     name: "Spices",
  //     img: mainImage2,
  //     productInformation:
  //       "We bring the best of spices and powders like chilli, coriander, turmeric and other essential ones from manufacturers and packers of spices. We pride ourselves on delivering the finest spices and powders, such as chilli, coriander, turmeric, and other essential varieties, sourced directly from reputable manufacturers and packagers, ensuring utmost freshness and quality in every batch.",
  //     information:
  //       "Our mission is to export the finest spices that have been an integral part of Indian cuisine for centuries, we export to Middle east",
  //     product: [
  //       {
  //         id: 3.1,
  //         name: "Turmeric Powder",
  //         available: true,
  //         characteristics: "2% to 3% and above",
  //         MinQuantity: "As per requirement",
  //         PackagingType:
  //           "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
  //         Variety:
  //           "White, Yellow and green gram split chic peas split kidney beans etc",
  //         img: turmericPowder,
  //       },
  //       {
  //         id: 3.2,
  //         name: "Coriander Powder",
  //         available: true,
  //         characteristics: "Finest A grade",
  //         MinQuantity: "500 KG",
  //         PackagingType:
  //           "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
  //         Variety: "Indian origin ",
  //         img: corriander,
  //       },
  //       {
  //         id: 3.3,
  //         name: "Chilli Powder",
  //         available: true,
  //         characteristics: "Pungency based on chilli used",
  //         MinQuantity: "500 KG",
  //         PackagingType: "Retail packs 1 Kg, 100g , 200g, 500g  and bulk 5 kg ",
  //         Variety:
  //           "Various grades of chilli power having hotness ( SHU ) and colour Grades ( ASTA ) as per buyers requirements ",
  //         img: chilliPowder,
  //       },
  //     ],
  //   },
  // ];

  return screenWidth < 1200 ? (
    <div className="ProductPageContainer">
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
            <Navbar screenWidth={screenWidth} />
          </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      {/*Body  */}

      <div className="sm:bg-black w-[100%] h-[50px] mt-[2%] flex items-center justify-center">
        <div className="text-white font-[16px] bg-gradient-to-r from-[#377440] to-[#0C391B] h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
      </div>

      {/* Products */}
      <div className="mt-[5%]">
        <ProductCarousal
          products={productInformation}
          ShowModal={showModalHandler}
          setSelectedProduct={setSelectedProduct}
          screenWidth={screenWidth}
        />
      </div>

      {showModal ? (
        <ProductModal
          onClose={showModalHandler}
          selectedProduct={selectedProduct}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    <div className="ProductPageContainer sm:h-[100vh]">
      <div>
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="w-[100%] h-[8%] mt-[2%] flex items-center justify-center">
        <div className="sm:bg-black text-white font-[16px] h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
      </div>

      <div className="w-[100%] h-[82%] flex flex-col justify-center items-center">
        <div className="w-[90%] h-[95%]">
          <div className="flex flex-col justify-center items-center h-[100%]">
            <ProductCarousal
              products={productInformation}
              ShowModal={showModalHandler}
              setSelectedProduct={setSelectedProduct}
            />
          </div>
        </div>
      </div>

      {showModal ? (
        <ProductModal
          onClose={showModalHandler}
          selectedProduct={selectedProduct}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductPage;
