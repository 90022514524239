import "./PremiumPage.scss";
import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";
import honeyImage from "../../Assets/NewAssets/honeypage/honeyImage.png";
import listBullet from "../../Assets/NewAssets/honeypage/list_bullet.png";
import { useNavigate } from "react-router-dom";
import { honeyTypes } from "../../Content/honeyProducts";

const Premium = ({ screenWidth }) => {
  const navigate = useNavigate();
  const navigateProduct = (id) => {
    navigate(`/product/honey/${id}`);
  };

  return screenWidth < 1200 ? (
    <div className="ProductPageContainer min-h-[100vh]">
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
            <Navbar screenWidth={screenWidth} />
          </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      {/*Body  */}

      <div className="w-[100%] h-[50px] mt-[2%] flex items-center justify-center relative">
        <div className="w-[90%] h-[80%] bg-[#FFBE00] rounded-[17px]"></div>
        <div className="absolute BannerBackground  text-white font-[16px] h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures Honey
        </div>
      </div>

      <div className="mt-[5%] w-[100%] flex flex-col justify-around items-center">
        <div>
          <div className="w-[100%]">
            <img
              alt="honeyImage"
              src={honeyImage}
              className="h-[100%] w-[100%]"
            />
          </div>
          <div className="w-[100%]">
            <div className="flex flex-col justify-around items-center bg-[#ffb400] w-[100%]">
              <div className="mt-[5%] w-[90%] h-[50px] text-[24px] font-semibold text-white">
                HONEY
              </div>
              <div className="mb-[5%] tracking-[2px] w-[90%] text-white text-[14px]">
                We bring you an array of delightful, natural honey varieties. We
                have explored the bountiful landscapes of India to introduce you
                to a range of lip-smacking floral honey options. Our carefully
                curated selection includes Litchi Honey, Berry Honey, Himachal
                Multiflora Honey, Rosewood Honey, Super Colony Honey, Dry-fruit
                Honey, Almond Honey and Cashew Honey
              </div>
              <div className="w-[90%] mt-[5%] mb-[5%] flex flex-col justify-between">
                {honeyTypes.map((honey) => {
                  return (
                    <div
                      className="cursor-pointer w-[100%] mt-[3%] flex justify-around"
                      key={honey.id}
                      onClick={() => navigateProduct(honey.id)}
                    >
                      <img src={listBullet} alt="list_bullet" />
                      <div className="tracking-[1px] rounded-lg shadow-lg bg-[#FCC86FF1] items-center pl-[5%] font-semibold text-[16px] text-white w-[80%] flex justify-start">
                        {honey.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="ProductPageContainer sm:h-[100vh]">
      <div>
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="w-[100%] h-[8%] mt-[2%] flex items-center justify-center relative">
        <div className="absolute BannerBackground text-black font-[16px] font-semibold h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
        <div className="w-[90%] h-[80%] bg-[#FFBE00] rounded-[17px]"></div>
      </div>

      <div className="w-[100%] h-[82%] flex flex-col justify-center items-center">
        <div className="w-[90%] h-[95%]">
          <div className="flex flex-row justify-center items-center h-[100%]">
            <div className="h-[90%] w-[50%]">
              <img
                src={honeyImage}
                alt="honeyImage"
                className="h-[100%] w-[100%]"
              />
            </div>
            <div className="h-[90%] w-[50%]">
              <div className="flex flex-col justify-center items-center bg-[#ffb400] h-[100%] w-[100%]">
                <div className="w-[80%] h-[10%] text-[24px] font-semibold text-white">
                  HONEY
                </div>
                <div className="tracking-[2px] w-[80%] h-[30%] text-white text-[14px]">
                  We bring you an array of delightful, natural honey varieties.
                  We have explored the bountiful landscapes of India to
                  introduce you to a range of lip-smacking floral honey options.
                  Our carefully curated selection includes Litchi Honey, Berry
                  Honey, Himachal Multiflora Honey, Rosewood Honey, Super Colony
                  Honey, Dry-fruit Honey, Almond Honey and Cashew Honey
                </div>
                <div className="w-[80%] h-[45%] flex flex-col justify-between">
                  {honeyTypes.map((honey) => {
                    return (
                      <div
                        className="cursor-pointer w-[65%] flex justify-between"
                        key={honey.id}
                        onClick={() => navigateProduct(honey.id)}
                      >
                        <img src={listBullet} alt="listbullet" />
                        <div className="text-white tracking-[1px] rounded-lg shadow-lg bg-[#FCC86FF1] items-center pl-[5%] font-semibold text-[16px] w-[80%] flex justify-start">
                          {honey.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premium;
