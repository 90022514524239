import React from "react";

const CompanyMoto = ({ title, details }) => {
  return (
    <div className="mb-2 bg-gradient-to-r from-[#377440] to-[#0C391B] p-4 rounded-lg">
      <div className="text-[16px] font-bold mb-2">{title} </div>
      <div className="text-[12px] tracking-[2px]">{details}</div>
    </div>
  );
};

export default CompanyMoto;
