import "./LandingPageDesktop.scss";
import { useNavigate } from "react-router-dom";

//Assets
import appLogo from "../../Assets/NewAssets/heroLogo.png";
import map from "../../Assets/NewAssets/map.png";
import transport from "../../Assets/NewAssets/transport.png";
import worldmap from "../../Assets/NewAssets/worldMap.png";

const LandingPageDesktop = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className="LandingPageDesktopContainer">
      <div>
        <div>
          {/* <img src={map} alt="map" /> */}
          <button onClick={handleNavigate}>Explore More</button>
        </div>

        <div>
          <div>ACROSS THE SEAS</div>
          <div>
            <img src={transport} alt="transport" />
            <img
              className="opacity-[55%]"
              src={worldmap}
              alt="non-tranparent"
            />
          </div>
        </div>
      </div>

      <div>
        <div>
          <div>
            <img src={appLogo} alt="applogo" />
            <div>Urban Sugar Ventures</div>
          </div>

          <div>
            <div>
              Welcome to Urban sugar ventures Pvt Ltd where excellence meets
              export.We take pride in being India's premier merchant export
              company, specializing in top-notch agricultural commodities and
              general merchandise.
            </div>
            <div>
              Committed to quality, our mission is to elevate your export
              experience with unparalleled products at competitive prices.
            </div>
            <div>
              With a relentless focus on excellence, we aim to emerge as a
              leader in the global market, providing you with the finest Indian
              goods.
            </div>
            <div>
              Join us on this journey of quality, reliability, and success.
            </div>
          </div>

          <div>Unlock seamless global market</div>

          <div>
            <div>
              MIG 06 Pt. Dindayal nagar Housing board colony, Sehore Madhya
              Pradesh ( 466001 ) Email - director1.urbansugar@gmail.com Phone-
              +91 8109641626
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageDesktop;
