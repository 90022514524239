import "./LandingPage.scss";
// Assets
import landingLogo from "../../Assets/NewAssets/logo1.png";
import LandingPageHero from "../../NewComponents/LandingPageComponent/LandingPageHero/LandingPageHero";
import PictureSection from "../../NewComponents/LandingPageComponent/PictureSection/PictureSection";
import LandingPageDesktop from "../../DesktopPages/LandingPageDesktop/LandingPageDesktop";

const LandingPage = ({ screenWidth }) => {
  return screenWidth < 1200 ? (
    <div className="LandingPageContainer">
      <div>
        <img src={landingLogo} alt="landingLogo" />

        <div>
          <LandingPageHero />

          <div>
            <PictureSection />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  ) : (
    <LandingPageDesktop />
  );
};

export default LandingPage;
