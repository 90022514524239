const DisplayDiv = ({
  src,
  alt,
  text,
  textHeading,
  alternate,
  backgroundColor,
  textColor,
}) => {
  return (
    <div className="flex h-[100%] w-[100%]">
      {alternate ? (
        <>
          <div className="h-[100%] w-[50%]">
            <img src={src} alt={alt} className="h-[100%] w-[100%]" />
          </div>
          <div
            style={{ background: backgroundColor, color: textColor }}
            className="h-[100%] w-[50%] flex flex-col justify-center items-center"
          >
            <div className="font-[500] h-[10%] text-[20px] w-[100%] flex justify-start pl-[5%] items-center">
              {textHeading}
            </div>
            <div className="text-[10px] w-[90%] mt-[2%] flex justify-center items-center">
              {text}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ background: backgroundColor, color: textColor }}
            className="h-[100%] w-[50%] flex flex-col justify-center items-center"
          >
            <div className="font-[500] text-[20px] w-[100%] flex justify-start pl-[5%] items-center">
              {textHeading}
            </div>
            <div className="text-[10px] w-[90%] mt-[2%] flex justify-center items-center">
              {text}
            </div>
          </div>
          <div className="h-[100%] w-[50%]">
            <img src={src} alt={alt} className="h-[100%] w-[100%]" />
          </div>
        </>
      )}
    </div>
  );
};

export default DisplayDiv;
