import { useNavigate } from "react-router-dom";
import "./HoneyProductPage.scss";
import { honeyTypes } from "../../Content/honeyProducts";
import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";
import leftArrow from "../../Assets/NewAssets/arrow2.png";

const HoneyProductPage = ({ screenWidth }) => {
  const honeyId = window.location.pathname.split("/")[3];
  const myProduct = honeyTypes[honeyId];

  const navigate = useNavigate();

  const handleBackButton = (e) => {
    e.stopPropagation();
    navigate("/honey");
  };

  return screenWidth < 1200 ? (
    <div className="ProductPageContainer min-h-[100vh]">
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
            <Navbar screenWidth={screenWidth} />
          </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="flex items-center w-[100%] h-[40px]">
        <img
          onClick={(e) => handleBackButton(e)}
          alt="leftarrow"
          src={leftArrow}
          className="shadow-lg z-[10] left-[-2%] mt-[5px] fixed h-[40px] w-[40px] rounded-[50%] ml-[5%]"
        />
      </div>

      {/*Body  */}

      <div className="w-[100%] h-[50px] mt-[2%] flex items-center justify-center relative">
        <div className="w-[90%] h-[80%] bg-[#FFBE00] rounded-[17px]"></div>
        <div className="absolute BannerBackground  text-white font-[16px] h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures Honey
        </div>
      </div>

      <div className="w-[100%] mt-[5%] flex flex-col justify-center items-center">
        <div className="w-[100%]">
          <div className="flex flex-col justify-center items-center">
            <div className="w-[100%]">
              <img
                src={myProduct.img}
                className="w-[100%]"
                alt="productImage"
              />
            </div>
            <div className="w-[100%]">
              <div className="flex flex-col justify-around items-center bg-[#ffb400] w-[100%]">
                <div className="mt-[5%] w-[80%] text-[24px] font-semibold text-white">
                  {myProduct.name}
                </div>
                <div className="mt-[5%] w-[80%] text-white text-[14px]">
                  {myProduct.description}
                </div>
                <div className="mt-[5%] mb-[5%] p-[30px] flex flex-col shadow-lg bg-[#FFF9D7] rounded-lg w-[80%] flex flex-col justify-between">
                  <div className=" flex w-[80%] flex justify-around">
                    <div className="w-[50%] ">Available</div>
                    <div className="w-[50%] ">
                      {myProduct.available ? "YES" : "NO"}
                    </div>
                  </div>

                  <div className="flex w-[80%] flex justify-around">
                    <div className="w-[50%]">Min</div>
                    <div className="w-[50%]">{myProduct.min}</div>
                  </div>

                  <div className="flex w-[80%] flex justify-around">
                    <div className="w-[50%]">Packaging Type</div>
                    <div className="w-[50%] ">{myProduct.packageType}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="ProductPageContainer sm:h-[100vh]">
      <div>
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="w-[100%] h-[8%] mt-[2%] flex items-center justify-center relative">
        <div className="absolute BannerBackground text-black font-[16px] font-semibold h-[80%] w-[90%] rounded-[17px] flex justify-center items-center">
          This is, Urban Sugar Ventures
        </div>
        <div className="w-[90%] h-[80%] bg-[#FFBE00] rounded-[17px]"></div>
      </div>

      <div className="w-[100%] h-[82%] flex flex-col justify-center items-center">
        <div className="w-[90%] h-[95%]">
          <div className="flex flex-row justify-center items-center h-[100%]">
            <div className="h-[90%] w-[50%]">
              <img
                src={myProduct.img}
                className="h-[100%] w-[100%]"
                alt="productImage"
              />
            </div>
            <div className="h-[90%] w-[50%]">
              <div className="flex flex-col justify-center items-center bg-[#ffb400] h-[100%] w-[100%]">
                <div className="w-[80%] h-[10%] text-[24px] font-semibold text-white">
                  {myProduct.name}
                </div>
                <div className="w-[80%] h-[30%] text-white text-[14px]">
                  {myProduct.description}
                </div>
                <div className="p-[30px] flex flex-col shadow-lg bg-[#FFF9D7] rounded-lg w-[80%] h-[30%] flex flex-col justify-between">
                  <div className=" flex w-[80%] flex justify-around">
                    <div className="w-[50%] h-[100%]">Available</div>
                    <div className="w-[50%] h-[100%]">
                      {myProduct.available ? "YES" : "NO"}
                    </div>
                  </div>

                  <div className="flex w-[80%] flex justify-around">
                    <div className="w-[50%] h-[100%]">Min</div>
                    <div className="w-[50%] h-[100%]">{myProduct.min}</div>
                  </div>

                  <div className="flex w-[80%] flex justify-around">
                    <div className="w-[50%] h-[100%]">Packaging Type</div>
                    <div className="w-[50%] h-[100%]">
                      {myProduct.packageType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoneyProductPage;
