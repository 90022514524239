// Packages Import
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Css Imports
import "./App.css";

// Logo Imports

// Components Imports
import LandingPage from "./NewPages/LandingPage/LandingPage";
import HomePage from "./NewPages/HomePage/HomePage";
import AboutPage from "./NewPages/AboutPage/AboutPage";
import ProductPage from "./NewPages/ProductsPage/ProductPage";
import ContactUs from "./NewPages/ContactUsPage/ContactUs";
import Premium from "./NewPages/PremiumPage/PremiumPage";
import HoneyProductPage from "./NewPages/HoneyProductPage/HoneyProductPage";

const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/landing"
          element={<LandingPage screenWidth={screenWidth} />}
        />
        <Route path="/" element={<HomePage screenWidth={screenWidth} />} />
        <Route
          path="/about"
          element={<AboutPage screenWidth={screenWidth} />}
        />
        <Route
          path="/products"
          element={<ProductPage screenWidth={screenWidth} />}
        />

        <Route
          path="/connect"
          element={<ContactUs screenWidth={screenWidth} />}
        />
        <Route path="/honey" element={<Premium screenWidth={screenWidth} />} />

        <Route
          path="/product/honey/:honeyId"
          element={<HoneyProductPage screenWidth={screenWidth} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
