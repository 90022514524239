// Content
export const vision = "Vision";
export const detail_vision =
  "To emerge as the premier export partner for manufacturers, farmers, and startups looking to export from India, while also catering toclients seeking quality products sourced from the country.";

export const mission = "Mission";
export const detail_mission =
  "Our mission is to make international business smooth for our clients and elevate their experience in doing business overseas.";

export const motive = "Motive";
export const detail_motive =
  " We wish to become a bridge between our respected buyers outside India and for their respected manufacturers within India, with our process expertise in Exports business";

export const vision_original =
  "To become the most trusted export partner of choice for all manufacturers, farmers and startups who are willing to export their products from India and to clients who wish to sourcetheir products from India. ";
