import "./LandingPageHero.scss";

//Assets
import rightArrow from "../../../Assets/NewAssets/arrow1.png";
import urbanlogo from "../../../Assets/NewAssets/heroLogo.png";

import { useNavigate } from "react-router-dom";

const LandingPageHero = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div onClick={handleNavigate} className="LandingPageHeroContainer">
      <div>
        <img src={urbanlogo} alt="logo" />
      </div>
      <div>Urban Sugar Ventures</div>
      <div>
        Welcome to Urban Sugar Ventures private limited where excellence meets
        export. We wish to become a bridge between our respected buyers outside
        Indian and for the respected manufacturers within India, with our
        process expertise in Exports business. With a relentless focus on
        excellence, we aim to emerge as a leader in the global market, providing
        you with the finest Indian goods.
      </div>
      <div>Unlock seamless global market</div>

      <div className="NavigateContainer">
        <img src={rightArrow} alt="Connect with us" />
      </div>
    </div>
  );
};

export default LandingPageHero;
