import "./DisplayProducts.scss";

const DisplayProducts = ({
  categorisedProduct,
  ShowModal,
  setSelectedProduct,
}) => {
  const handleClick = (product) => {
    setSelectedProduct(product);
    ShowModal();
  };

  return (
    <div className="bg-none w-[70%] h-[25%] left-[10%] top-[30%] sm:shadow-lg sm:p-[1%] sm:flex sm:flex-col sm:justify-center sm:items-center sm:bg-[#ffffffcc] sm:rounded-[7px] absolute sm:w-[130px] sm:h-[250px] sm:left-[45%] sm:top-[20%]">
      <div className="overflow-x-auto scroll-container w-[100%] h-[80%] sm:overflow-y-auto sm:h-[200px] flex sm:flex-col">
        {categorisedProduct.map((product) => (
          <img
            key={product.id}
            onClick={() => handleClick(product)}
            src={product.img}
            alt="prod"
            className="cursor-pointer w-[100%] h-[100%] sm:w-[100%] sm:h-[50%] relative"
          />
        ))}
      </div>
    </div>
  );
};

export default DisplayProducts;
