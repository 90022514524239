export const partners = "PARTNERS";
export const details_Partners =
  "We provide EXPORT services to small manufacturers and startups who are willing to export their products overseas without engaging any FIXED COST. We partner with their companies and manage their export process like a member of their own company against profit sharing.";

export const merchant = "MERCHANT";
export const details_Merchant =
  "We are a proficient merchant exporter from India. Working with a range of products from commodities to essentials and luxurious high-value goods.";

export const procurement = "PROCUREMENT";
export const details_Procurement =
  "To enhance the business activities of manufacturers, traders, and importers we are providing sourcing service on a commission basis for products of desired quality & quantity from the domestic Indian market";

export const consultant = "CONSULTANCY";
export const details_consultancy =
  "We are equipped to provide consultancy for our clients who wish to set up their in-house teams for exports.";
