import "./PictureSection.scss";

//Assets
import map from "../../../Assets/NewAssets/map.png";
import transport from "../../../Assets/NewAssets/transport.png";

const PictureSection = () => {
  return (
    <div className="PictureSectionContainer">
      <img src={map} alt="worldMap" />
      <img src={transport} alt="transport" />
    </div>
  );
};

export default PictureSection;
