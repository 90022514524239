//Assets Main Images
import mainImage1 from "../Assets/NewAssets/products/main1.png";
import mainImage2 from "../Assets/NewAssets/products/main2.png";
import mainImage3 from "../Assets/NewAssets/products/main3.png";

// Special Products
import ghee from "../Assets/NewAssets/products/specialProducts/Ghee2.png";
import milltes from "../Assets/NewAssets/products/specialProducts/Millets.png";
import pershable from "../Assets/NewAssets/products/specialProducts/Parishables.png";

// Pulses
import pulses from "../Assets/NewAssets/products/pulses/pulses.png";
import kabuliChana from "../Assets/NewAssets/products/pulses/kabuliChana.png";

//Spices
import turmericPowder from "../Assets/NewAssets/products/spices/tumeric.png";
import corriander from "../Assets/NewAssets/products/spices/coriander.png";
import chilliPowder from "../Assets/NewAssets/products/spices/chilliPowder.png";

export const productInformation = [
  {
    id: 1,
    name: "Special Products",
    img: mainImage1,
    productInformation:
      "We procure fresh materials as per buyers' requirements of specifications and also keep stocks for upcoming  export shipments",
    information: "We export these special product to the Middle East region.",
    product: [
      {
        id: 1.1,
        name: "A2 Gir cow ghee",
        available: true,
        MinQuantity: "As per requirement",
        PackagingType: "Retail packs and Bulk packs available",
        Variety: "A2 Premium Gir cow ghee",
        img: ghee,
      },
      {
        id: 1.2,
        name: "Millets",
        available: true,
        MinQuantity: "As per requirement",
        PackagingType: "Retail packs and Bulk packs available",
        Variety:
          "Finger millet, Foxtail millet, Proso millet and Barnyard millet",
        img: milltes,
      },
      {
        id: 1.3,
        name: "Pershable",
        available: true,
        MinQuantity: "As per requirement",
        PackagingType: "Mesh packs / as per client requirement",
        Variety: "Onion / Garlic / Ginger / Potato ",
        img: pershable,
      },
    ],
  },
  {
    id: 2,
    name: "Pulses",
    img: mainImage3,
    productInformation:
      "We are connected with different mills and stockiest to provide the best quality of pulses and lentils from Central India, Rajasthan, and other states as per buyers' requirements. Leveraging our connections with different mills and stockiest, we guarantee the supply of premium-quality pulses and lentils sourced from Central India, Rajasthan, and other states, precisely meeting the demand of our buyers.",
    information:
      "We provide the world with high-quality, protein-packed pulses that embody the essence of India’s agricultural excellence.",
    product: [
      {
        id: 2.1,
        name: "Pulses",
        available: true,
        MinQuantity: "As per requirement",
        PackagingType: "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
        Variety:
          "White, Yellow and Green gram split, Chic Peas split, Kidney beans",
        img: pulses,
      },
      {
        id: 2.2,
        name: "Chickpea - Desi & Kabuli",
        available: true,
        MinQuantity: "As per requirement",
        PackagingType: "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
        Variety: "42/44 (12mm),44/46 (11mm), 58/60 (8-9mm)",
        img: kabuliChana,
      },
    ],
  },

  {
    id: 3,
    name: "Spices",
    img: mainImage2,
    productInformation:
      "We bring the best of spices and powders like chili, coriander, turmeric, and other essential ones from manufacturers and packers of spices. We pride ourselves on delivering the finest spices and powders, such as chili, coriander, turmeric, and other essential varieties, sourced directly from reputable manufacturers and packagers, ensuring utmost freshness and quality in every batch.",
    information:
      "Our mission is to export the finest spices that have been an integral part of Indian cuisine for centuries, we export to the Middle east",
    product: [
      {
        id: 3.1,
        name: "Turmeric Powder",
        available: true,
        characteristics: "2% to 3% and above",
        MinQuantity: "As per requirement",
        PackagingType: "Retail packs 100g, 200g, 500g and 1Kg/5kg retail packs",
        Variety: "All varieties of indian origin",
        img: turmericPowder,
      },
      {
        id: 3.2,
        name: "Coriander Powder",
        available: true,
        characteristics: "Finest A grade",
        MinQuantity: "500 KG",
        PackagingType: "Retail packs 500g, 1kg, 5kg and bulk 30/50 kg PP bags",
        Variety: "Indian origin ",
        img: corriander,
      },
      {
        id: 3.3,
        name: "Chilli Powder",
        available: true,
        characteristics: "Pungency based on chilli used",
        MinQuantity: "500 KG",
        PackagingType: "Retail packs 1 Kg, 100g , 200g, 500g  and bulk 5 kg ",
        Variety:
          "Various grades of chilli power having hotness (SHU) and colour Grades ( ASTA ) as per buyers requirements ",
        img: chilliPowder,
      },
    ],
  },
];
