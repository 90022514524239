import DisplayProducts from "../DisplayProducts/DisplayProducts";
import rightArrow from "../../../Assets/NewAssets/arrow1.png";
import leftArrow from "../../../Assets/NewAssets/arrow2.png";

const ProductCard = ({
  productIndexProp,
  setProductIndex,
  information,
  ShowModal,
  setSelectedProduct,
  screenWidth,
}) => {
  const handleIndex = (index) => {
    if (index > information.product.length) {
      setProductIndex(0);
    }

    if (index >= information.product.length) {
      setProductIndex(0);
    } else if (index < 0) {
      setProductIndex(information.product.length - 1);
    } else {
      setProductIndex(index);
    }
  };

  const handleModal = (product) => {
    setSelectedProduct(product);
    ShowModal();
  };

  return screenWidth < 1200 ? (
    <div className="relative flex flex-col h-[100vh] border border-sky-900">
      <div className="z-[1] top-[30%] h-[140px] absolute flex justify-center items-center w-[100%] ">
        <div className="relative bg-[#ffffff80] h-[80%] flex flex-row justify-around items-center w-[45%] rounded-lg">
          {productIndexProp > 0 ? (
            <img
              onClick={() => handleIndex(productIndexProp - 1)}
              alt="lefttArrow"
              src={leftArrow}
              className="left-[-9%] absolute cursor-pointer h-[30%] rounded-[50%]"
            />
          ) : (
            ""
          )}

          <div>
            <img
              src={information.product[productIndexProp].img}
              alt="productimage"
              className="h-[100px]"
              onClick={() => handleModal(information.product[productIndexProp])}
            />
          </div>

          {productIndexProp < information.product.length - 1 ? (
            <img
              src={rightArrow}
              onClick={() => handleIndex(productIndexProp + 1)}
              alt="rightArrow"
              className="right-[-9%] absolute cursor-pointer h-[30%] rounded-[50%]"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="h-[50%] w-[100%] relative">
        <img
          src={information.img}
          alt="productImage"
          className="h-[100%] w-[100%]"
        />
        <div className="p-[5%] tracking-[2px] text-[12px] text-white flex justify-center items-center absolute top-[5%] left-[10%] bg-[#0D0D0D80] w-[80%] h-[25%]">
          {information.information}
        </div>
      </div>
      <div className="relative flex justify-center items-center min-h-[50%] w-[100%] bg-[#FFE9BE]">
        <div className="w-[70%] min-h-[40%] flex flex-col justify-around">
          <div className="mb-[5%] text-black font-bold tracking-[0px]">
            {information.name.toUpperCase()}
          </div>
          <div className="tracking-[0px]">{information.productInformation}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="sm:w-[100%] sm:h-[100%] sm:flex sm:relative">
      <div className="sm:w-[50%] sm:relative">
        <img
          src={information.img}
          alt="productImage"
          className="sm:h-[100%] sm:w-[100%]"
        />
        <div className="sm:p-[2%] sm:text-[12px] sm:text-white sm:flex sm:justify-center sm:items-center sm:absolute sm:top-[80%] sm:left-[15%] sm:bg-[#0D0D0D80] sm:w-[70%] sm:h-[10%]">
          {information.information}
        </div>
      </div>
      <div className="sm:flex sm:justify-center sm:items-center sm:w-[50%] sm:bg-[#FFE9BE]">
        <div className="sm:w-[40%] sm:min-h-[300px] sm:flex sm:flex-col sm:justify-around">
          <div className="sm:text-black sm:font-bold sm:tracking-[1px] text-[28px]">
            {information.name.toUpperCase()}
          </div>
          <div className="sm:tracking-[1px] text-[18px]">
            {information.productInformation}
          </div>
        </div>
      </div>
      <DisplayProducts
        categorisedProduct={information.product}
        ShowModal={ShowModal}
        setSelectedProduct={setSelectedProduct}
        screenWidth={screenWidth}
      />
    </div>
  );
};

export default ProductCard;
