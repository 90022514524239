import { useState } from "react";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Email = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [enqSubject, setEnqSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendMail = async () => {
    const enquireButton = document.getElementById("enquireButton");

    if (!email || !name || !enqSubject || !message) {
      return;
    }

    enquireButton.disabled = true;
    await axios.post(
      "https://urban-sugar-ventures-server.onrender.com/send-email",
      {
        email,
        name,
        enqSubject,
        message,
      }
    );

    toast.success("Email Sent", {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Zoom,
    });

    setTimeout(() => {
      enquireButton.disabled = false;
    }, 1500);
  };

  return (
    <div className="flex flex-col w-[100%] h-[100%] items-center justify-around sm:h-[100%] sm:w-[100%] sm:flex sm:flex-col sm:justify-around sm:items-start">
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="Enter your email"
        className="w-[90%] h-[15%] pl-[5%] sm:w-[80%] sm:text-[12px] sm:h-[30px] sm:pl-[2%] sm:rounded-[7px]"
      />
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-[90%] h-[15%] pl-[5%] sm:w-[80%] sm:text-[12px] sm:h-[30px] sm:pl-[2%] sm:rounded-[7px]"
        type="text"
        placeholder="Enter your name"
      />
      <input
        value={enqSubject}
        onChange={(e) => setEnqSubject(e.target.value)}
        className="w-[90%] h-[15%] pl-[5%] sm:w-[80%] sm:text-[12px] sm:h-[30px] sm:pl-[2%] sm:rounded-[7px]"
        type="text"
        placeholder="Enquire about "
      />
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-[90%] h-[15%] pl-[5%] sm:w-[80%] sm:text-[12px] sm:h-[30px] sm:pl-[2%] sm:rounded-[7px]"
        type="text"
        placeholder="Write your message"
      />
      <button
        id="enquireButton"
        onClick={sendMail}
        className="w-[60%] h-[15%] text-white tracking-[2px] sm:font-semibold rounded-[7px] bg-[#00b200] sm:w-[30%] sm:text-[14px] sm:h-[35px] sm:flex justify-center items-center"
      >
        Enquire Now
      </button>
    </div>
  );
};

export default Email;
