import "./ContactUs.scss";

// Components
import { ToastContainer } from "react-toastify";
import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";

//Assets
// import cert1 from "../../Assets/NewAssets/connect/img1.png";
// import cert2 from "../../Assets/NewAssets/connect/img2.png";
import cert1 from "../../Assets/NewAssets/connect/img1.jpg";
import cert2 from "../../Assets/NewAssets/connect/img2.jpg";
import cert3 from "../../Assets/NewAssets/connect/img3.png";
import map from "../../Assets/NewAssets/connect/map.png";
import Email from "../../NewComponents/ConnectPageComponent/EmailComponent/Email";

const ContactUs = ({ screenWidth }) => {
  const handleMapClick = () => {
    const url =
      "https://www.google.com/maps/place/23%C2%B012'43.2%22N+77%C2%B006'28.8%22E/@23.211989,77.107997,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.211989!4d77.107997?entry=ttu";
    window.open(url, "_blank");
  };

  return screenWidth < 800 ? (
    <div className="bg-[#f4efe5]">
      <ToastContainer
        style={{
          marginLeft: "auto",
          width: "75%",
          top: "450px",
          right: "50px",
        }}
      />
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      {/*Body  */}
      <div className="mt-[7%]">
        <div className="tracking-[2px] h-[250px] w-[100% border border-sky-900]">
          <div className="font-semibold pl-[5%] mb-[2%]">Enquire Us</div>
          <Email />
        </div>

        <div className="mt-[15%]">
          {/* <div className="font-semibold tracking-[2px] pl-[2%] h-[10%]">
            Corporate Office
          </div>
          <div className="tracking-[1px] pl-[2%] h-[90%]  text-[14px] w-[90%] mt-[2%]">
            F-297 sector 63 Gautam Buddha Nagar Noida 201301 (UP)
          </div> */}
          <div className="font-semibold tracking-[2px] pl-[2%] h-[10%] mt-[2%]">
            Registered Office
          </div>
          <div className="tracking-[1px] pl-[2%] h-[90%] text-[14px] w-[90%] mt-[2%]">
            MIG-6, Pt. Dindayal nagar Housing board colony (Near lockdown park)
            (Behind hanuman mandir) Sehore 466001
          </div>

          <div>
            <div className="font-semibold tracking-[2px] pl-[2%] h-[10%] mt-[15%] mb-[5%]">
              Certifications
            </div>
            <div className="flex flex-row justify-between tracking-[2px] pl-[2%] h-[90%] w-[100%]">
              <img src={cert1} alt="img1" className="h-[100%] w-[30%]" />
              <img src={cert2} alt="img2" className="h-[100%] w-[30%]" />
              <img src={cert3} alt="img3" className="h-[100%] w-[30%]" />
            </div>
          </div>

          <div
            className="h-
          [250px] bg-[none] mt-[5%] flex flex-col justify-between"
          >
            <div className="mb-[5%] tracking-[2px] font-semibold pl-[2%] h-[5%]">
              Reach Out
            </div>
            <div
              onClick={handleMapClick}
              className="h-[80%] w-[100%] cursor-pointer"
            >
              <img
                src={map}
                alt="map"
                className="h-[100%] w-[100%] cursor-pointer border border-black-900"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="ContactUsPageContainer snm:h-[100vh]">
      <ToastContainer />

      <div>
        <Navbar screenWidth={screenWidth} />
      </div>

      <div className="w-[100%] h-[90%] flex flex-col justify-center items-center">
        <div className="w-[100%] h-[100%] flex flex-col justify-around items-center">
          <div className="w-[90%] h-[45%] flex flex-row justify-center items-center">
            <div className="w-[50%] h-[100%] ">
              <div className="font-semibold tracking-[2px] pl-[2%] h-[10%]">
                Enquire Us
              </div>
              <div className="tracking-[5px] pl-[2%] h-[90%]">
                <Email />
              </div>
            </div>

            {/*  */}

            <div className="w-[50%] h-[100%] ">
              <div className="font-semibold tracking-[2px] pl-[2%] h-[10%]">
                Certifications
              </div>
              <div className="flex flex-row justify-between tracking-[2px] pl-[2%] h-[90%] w-[100%]">
                <img src={cert1} alt="img1" className="h-[100%] w-[30%]" />
                <img src={cert2} alt="img2" className="h-[100%] w-[30%]" />
                <img src={cert3} alt="img3" className="h-[100%] w-[30%]" />
              </div>
            </div>
          </div>
          <div className="w-[90%] h-[45%] flex flex-row justify-between">
            <div className="w-[20%] h-[100%] flex flex-col ">
              {/* <div className="font-semibold tracking-[2px] pl-[2%] h-[10%]">
                Corporate Office
              </div>
              <div className="tracking-[1px] pl-[2%] h-[90%]  text-[14px] w-[60%]">
                F-297 sector 63 Gautam Buddha Nagar Noida 201301 (UP)
              </div> */}
              <div className="font-semibold tracking-[2px] pl-[2%] h-[10%] mt-[7%] mb-[5%]">
                Registered Office
              </div>
              <div className="tracking-[1px] pl-[2%] h-[90%] text-[18px] w-[70%]">
                MIG-6, Pt. Dindayal nagar Housing board colony (Near lockdown
                park) (Behind hanuman mandir) Sehore 466001
              </div>
            </div>

            {/*  */}
            <div className="sm:w-[78%] sm:h-[100%] flex justify-center items-center border shadow-lg rounded-lg">
              <div
                onClick={handleMapClick}
                className="h-[100%] w-[100%] cursor-pointer"
              >
                <img
                  alt="map"
                  src={map}
                  className="h-[100%] w-[100%] cursor-pointer rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
