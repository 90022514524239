import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const MobileBannerCarousal = ({ homeBg, homeBg2, homeBg3 }) => {
  return (
    <div id="carouselExampleCaptions" className="carousel slide h-[200px] mt-4">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner h-full">
        <div className="carousel-item active h-full">
          <img src={homeBg} className="d-block w-100 h-full" alt="Slide 1" />
        </div>
        <div className="carousel-item active h-full">
          <img src={homeBg2} className="d-block w-100 h-full" alt="Slide 2" />
        </div>
        <div className="carousel-item active h-full">
          <img src={homeBg3} className="d-block w-100 h-full" alt="Slide 3" />
        </div>
      </div>
    </div>
  );
};

export default MobileBannerCarousal;
