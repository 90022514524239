import multiFlora from "../Assets/NewAssets/honeypage/multiFlora.png";
import himachal from "../Assets/NewAssets/honeypage/himachalMultiFlora.png";
import berry from "../Assets/NewAssets/honeypage/berry.png";
import litchi from "../Assets/NewAssets/honeypage/litchy.png";
import roseWood from "../Assets/NewAssets/honeypage/roseWood.png";

export const honeyTypes = [
  {
    id: 0,
    name: "Multiflora Honey",
    description:
      "Multiflora honey is a type of honey that is produced in wild forest regions. The bees that produce this honey gather nectar from a variety of wildflowers, which gives the honey its unique flavor. Multiflora honey is considered to be one of the purest and most natural kinds of honey available.",
    available: true,
    min: "Any",
    packageType: "Glass/ Pet retail bottle Bucket/ Drums Bulk pack",
    img: multiFlora,
  },
  {
    id: 1,
    name: "Himachal Multiflora Honey",
    description:
      "Our Himachal multiflora honey is sourced from the rolling hills of Himachal Pradesh. Made from the nectar of various wildflowers, it has a rich floral taste that will enchant your senses.",
    available: true,
    min: "Any",
    packageType: "Glass/ Pet retail bottle Bucket/ Drums Bulk pack",
    img: himachal,
  },
  {
    id: 2,
    name: "Berry Honey",
    description:
      "Berry or Sidr Honey is the rarest type of Honey that Hunza Delight collects from the mountains of the Himalayas, It has more minerals, vitamins, nutrients, and antioxidants than any other variety of Honey.",
    available: true,
    min: "Any",
    packageType: "Glass/ Pet retail bottle Bucket/ Drums Bulk pack",
    img: berry,
  },
  {
    id: 3,
    name: "Litchi Honey",
    description:
      "It is made by honeybees from the sugary nectar. Litchi honey comes from the blossom of litchi flowers. This pure and unadulterated honey is buttery, light amber, and smoother than regular honey.",
    available: true,
    min: "Any",
    packageType: "Glass/ Pet retail bottle Bucket/ Drums Bulk pack",
    img: litchi,
  },
  {
    id: 4,
    name: "Rosewood Honey",
    description:
      "The usage of rosewood raw honey has been known to help with allergies, asthma, and other respiratory problems. It is a great natural remedy that helps soothe the throat and lungs. In addition, it has anti-inflammatory and antibacterial properties that can help with infection.",
    available: true,
    min: "Any",
    packageType: "Glass/ Pet retail bottle Bucket/ Drums Bulk pack",
    img: roseWood,
  },
];
