import { createPortal } from "react-dom";
import React from "react";

const ProductModal = ({ onClose, selectedProduct }) => {
  return createPortal(
    <div
      onClick={onClose}
      className="overflow-none fixed top-0 left-0 w-full h-full bg-[#00000080] flex justify-center items-center z-[100]"
    >
      <div className="w-[90%] sm:w-[35%] flex-col flex bg-white rounded-[17px] items-center relative">
        {/* Wings */}
        <div className="z-[-1] absolute left-[98%] h-[80%] w-[5%] top-[10%] bg-[#285B30] rounded-[17px] "></div>
        <div className="z-[-1] absolute right-[98%] h-[80%] w-[5%] top-[10%] bg-[#285B30] rounded-[17px] "></div>

        {/*  */}
        <div className="mt-[3%] w-[80%] flex">
          <img
            alt="product"
            className="h-[80px] w-[80px]"
            src={selectedProduct.img}
          />
          <div className="ml-[5%] flex items-center w-[70%] text-[24px] font-semibold">
            {selectedProduct.name}
          </div>
        </div>

        {/*  */}

        <div className="mt-[5%] mb-[5%] w-[80%] flex flex-col">
          {/*  */}
          {/* <div className="mt-[2%] w-[100%] flex">
            <div className="w-[40%] font-semibold">Available</div>
            <div className="w-[60%]">
              {selectedProduct.available ? "Yes" : "No"}
            </div>
          </div> */}
          {/*  */}
          {/* <div className="mt-[2%] w-[100%] flex">
            <div className="w-[40%] font-semibold">Minimum Order</div>
            <div className="w-[60%]">{selectedProduct.MinQuantity}</div>
          </div> */}
          {/*  */}
          <div className="mt-[2%] w-[100%] flex">
            <div className="w-[40%] font-semibold">Packing type - </div>
            <div className="w-[60%]">{selectedProduct.PackagingType}</div>
          </div>
          {/*  */}
          <div className="mt-[5%] w-[100%] flex">
            <div className="w-[40%] font-semibold">Variety - </div>
            <div className="w-[60%]">
              {selectedProduct.Variety.split(",").map((vari) => {
                return <div>{vari}</div>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default ProductModal;
