import Navbar from "../../NewComponents/CommonComponent/Navbar/Navbar";
import "./HomePage.scss";
import { useState, useEffect } from "react";

import {
  mission,
  vision,
  motive,
  detail_mission,
  detail_vision,
  detail_motive,
} from "../../Content/homepageContent";

// Assets
// import homeBg from "../../Assets/new-banner-update-sept-2024/banner1.png";
// import homeBg2 from "../../Assets/new-banner-update-sept-2024/banner2.png";
// import homeBg3 from "../../Assets/new-banner-update-sept-2024/banner3.png";

import homeBg from "../../Assets/new-banner-update-sept-2024/banner1.webp";
import homeBg2 from "../../Assets/new-banner-update-sept-2024/banner2.webp";
import homeBg3 from "../../Assets/new-banner-update-sept-2024/banner3.webp";

import homeContent from "../../Assets/NewAssets/home/homeContent.png";
import groupedIcons from "../../Assets/NewAssets/home/gorupedIcons.png";
import CompanyMoto from "../../NewComponents/HomePageComponent/company-moto/company-moto";
import MobileBannerCarousal from "../../NewComponents/HomePageComponent/home-banner-carousal-mobile/home-banner-carousal-mobile";

const HomePage = ({ screenWidth }) => {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);

  // List of background images
  const backgroundImages = [homeBg, homeBg2, homeBg3];
  const changedBackground = backgroundImages[currentBgIndex];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBgIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 5000);

    return () => {
      // Cleanup the interval on component unmount
      clearInterval(intervalId);
    };
  });

  return screenWidth < 1200 ? (
    <div className="HomePageContainer">
      {/* Uncomment for fixed navbar */}

      {/* <div className="bg-none fixed w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div> */}

      <div className="bg-none sticky top-[0%] w-[100%] z-10 h-[60px] flex justify-center mt-[2%] mb-[2%]">
        <Navbar screenWidth={screenWidth} />
      </div>

      <MobileBannerCarousal
        homeBg={homeBg}
        homeBg2={homeBg2}
        homeBg3={homeBg3}
      />

      <div className="mt-[10%] mb-[2%] flex justify-center items-center flex-col relative">
        <div className="w-[90%] rounded-[18px] text-white h-full">
          <CompanyMoto title={mission} details={detail_mission} />
          <CompanyMoto title={vision} details={detail_vision} />
          <CompanyMoto title={motive} details={detail_motive} />
        </div>

        {/* Uncomment for older version */}

        {/* <div className="w-[90%] rounded-[18px] border border-sky-900 p-[5%] bg-gradient-to-r from-[#377440] to-[#0C391B] text-white">
          <div className="text-[18px] font-bold">{mission} </div>
          <div className="text-[14px] tracking-[2px]">{detail_mission}</div>
          <div className="text-[18px] font-bold mt-[5%]">{vision} </div>
          <div className="text-[14px] tracking-[2px]">{detail_vision}</div>

          <div className="text-[18px] font-bold mt-[5%]">{motive}</div>
          <div className="text-[14px] tracking-[2px]">{detail_motive}</div>
        </div> */}
      </div>
    </div>
  ) : (
    <div className="HomePageContainer sm:h-[100vh]">
      <div>
        <Navbar screenWidth={screenWidth} />
      </div>
      <div className="sm:h-[65%] sm:relative sm:justify-center items-center flex">
        <img
          src={changedBackground}
          alt="HomeScreen"
          className="sm:h-[100%] sm:w-[91%] sm:relative"
        />
      </div>
      <div className="sm:h-[40%] sm:flex sm:flex-row sm:justify-around sm:items-center">
        <div className="w-[91%] flex h-[100%] items-center justify-between">
          {/* 
        
        */}
          <div className="border sm:bg-gradient-to-r from-[#377440] to-[#0C391B] sm:text-white sm:w-[25%] sm:h-[80%] sm:flex sm:justify-center rounded-[16px]">
            <div className="sm:w-[60%]">
              <div className="mt-[5%] mb-[2%] text-[20px]">{mission}</div>
              <div className="text-[10px] tracking-[2px]">{detail_mission}</div>
            </div>
          </div>

          <div className="sm:bg-gradient-to-r from-[#377440] to-[#0C391B] sm:text-white sm:w-[25%] sm:h-[80%] sm:flex sm:justify-center rounded-[16px]">
            <div className="sm:w-[70%]">
              <div className="mt-[5%] mb-[2%] text-[20px]">{vision} </div>
              <div className="text-[10px] tracking-[2px]">{detail_vision}</div>
            </div>
          </div>
          {/*  */}
          <div className="sm:bg-gradient-to-r from-[#377440] to-[#0C391B] sm:text-white sm:w-[25%] sm:h-[80%] sm:flex sm:justify-center rounded-[16px]">
            <div className="sm:w-[70%]">
              <div className="mt-[5%] mb-[2%] text-[20px]">{motive}</div>
              <div className="text-[10px] tracking-[2px]">{detail_motive}</div>
            </div>
          </div>
          <div
            className="sm:text-white sm:w-[15%] sm:h-[80%] sm:flex sm:justify-center sm:items-center rounded"
            // className="sm:bg-gradient-to-r from-[#377440] to-[#0C391B] sm:text-white sm:w-[15%] sm:h-[80%] sm:flex sm:justify-center sm:items-center rounded"
          >
            <img
              src={groupedIcons}
              alt="groupedIcon"
              className="sm:h-[80%] sm:w-[70%] "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
