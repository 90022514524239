import { useNavigate } from "react-router-dom";

import NavButton from "../NavButton/NavButton";
//Assets
import navLogo from "../../../Assets/NewAssets/logo_mobile_navbar.png";
// import home from "../../../Assets/NewAssets/icons/home.png";
import home from "../../../Assets/NewAssets/icons/home2.png";
// import about from "../../../Assets/NewAssets/icons/about.png";
import about from "../../../Assets/NewAssets/icons/about2.png";
// import connect from "../../../Assets/NewAssets/icons/connect.png";
import connect from "../../../Assets/NewAssets/icons/connect2.png";
// import product from "../../../Assets/NewAssets/icons/products.png";
import product from "../../../Assets/NewAssets/icons/products2.png";

// Honey Asset
import HoneyAsset from "../../../Assets/NewAssets/honeypage/wings.png";
import honeyButton from "../../../Assets/NewAssets/honeypage/honey.png";
// import premiumDot from "../../../Assets/NewAssets/honeypage/Premium.png";
import honeyLandingLogo from "../../../Assets/NewAssets/honeypage/honey_Logo.png";

import { useState } from "react";

const Navbar = ({ screenWidth }) => {
  const navigate = useNavigate();
  const [url, setURL] = useState(window.location.pathname);

  const handleNavigation = (route) => {
    navigate(route);
    setURL(route);
  };

  return (
    <div className="sm:shadow-lg sm:justify-center sm:mt-[0] sm:w-[100%] sm:rounded-[0] sm:h-[60px] w-[90%]  sm:h-[100%] h-[100%] flex justify-between items-center rounded-[18px] shadow-xl sticky top-[0] bg-white">
      {url === "/honey" ? (
        <img
          onClick={() => navigate("/landing")}
          src={honeyLandingLogo}
          alt="logonav"
          className="cursor-pointer sm:h-[60%] sm:w-[3%]  h-[70%] w-[15%]"
        />
      ) : (
        <img
          onClick={() => navigate("/landing")}
          src={navLogo}
          alt="logonav"
          className="cursor-pointer sm:h-[60%] sm:w-[3%]  h-[70%] w-[15%]"
        />
      )}
      {screenWidth < 1200 ? (
        <div className="text-[14px] font-medium w-[70%] h-[100%] mr-[7%] flex items-center justify-around">
          <div
            style={url === "/" ? { background: "#84d891" } : { background: "" }}
            onClick={() => handleNavigation("/")}
            className="h-[65%] w-[15%] flex justify-center items-center rounded"
          >
            <NavButton src={home} alt="home" />
          </div>
          <div
            style={
              url === "/honey" ? { background: "#FFA800" } : { background: "" }
            }
            onClick={() => handleNavigation("/honey")}
            className="h-[65%] w-[15%] flex justify-center items-center rounded relative"
          >
            <NavButton src={honeyButton} alt="connect" />
          </div>
          <div
            style={
              url === "/products"
                ? { background: "#84d891" }
                : { background: "" }
            }
            onClick={() => handleNavigation("/products")}
            className="h-[65%] w-[15%] flex justify-center items-center rounded"
          >
            <NavButton src={product} alt="product" />
          </div>
          <div
            style={
              url === "/about" ? { background: "#84d891" } : { background: "" }
            }
            onClick={() => handleNavigation("/about")}
            className="h-[65%] w-[15%] flex justify-center items-center rounded"
          >
            <NavButton src={about} alt="about" />
          </div>

          <div
            style={
              url === "/connect"
                ? { background: "#84d891" }
                : { background: "" }
            }
            onClick={() => handleNavigation("/connect")}
            className="h-[65%] w-[15%] flex justify-center items-center rounded"
          >
            <NavButton src={connect} alt="connect" />
          </div>
        </div>
      ) : (
        <div
          className="sm:w-[30%] sm:text-[12px] sm:font-semibold
         w-[60%] h-[100%] mr-[7%] flex items-center justify-around "
        >
          <div
            style={
              url === "/"
                ? {
                    background: "#84d891",
                    borderRadius: "7px",
                    padding: "15px",
                    minWidth: "75px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    fontSize: "14px",
                  }
                : { background: "" }
            }
            onClick={() => handleNavigation("/")}
            className="sm:h-[45%] sm:w-[12%] cursor-pointer flex justify-center items-center"
          >
            Home
          </div>
          <div
            style={
              url === "/honey"
                ? {
                    background: "#f9b13e",
                    borderRadius: "7px",
                    padding: "15px",
                    minWidth: "75px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    fontSize: "14px",
                  }
                : { background: "", color: "#FFA800", fontWeight: "700" }
            }
            onClick={() => handleNavigation("/honey")}
            className="sm:h-[45%] sm:w-[12%] cursor-pointer flex justify-center items-center relative"
          >
            <img
              src={HoneyAsset}
              className="border h-[75%] left-[80%] top-[-30%] absolute"
              alt="honey"
            />
            Honey
          </div>

          <div
            style={
              url === "/products"
                ? {
                    background: "#84d891",
                    borderRadius: "7px",
                    padding: "15px",
                    minWidth: "75px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    fontSize: "14px",
                  }
                : { background: "" }
            }
            onClick={() => handleNavigation("/products")}
            className="sm:h-[45%] sm:w-[12%] cursor-pointer flex justify-center items-center"
          >
            Product
          </div>
          <div
            style={
              url === "/about"
                ? {
                    background: "#84d891",
                    borderRadius: "7px",
                    padding: "15px",
                    minWidth: "75px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    fontSize: "14px",
                  }
                : { background: "" }
            }
            onClick={() => handleNavigation("/about")}
            className="sm:h-[45%] sm:w-[12%] cursor-pointer flex justify-center items-center"
          >
            About{" "}
          </div>

          <div
            style={
              url === "/connect"
                ? {
                    background: "#84d891",
                    borderRadius: "7px",
                    padding: "15px",
                    minWidth: "75px",
                    fontWeight: "600",
                    letterSpacing: "2px",
                    fontSize: "14px",
                  }
                : { background: "" }
            }
            onClick={() => handleNavigation("/connect")}
            className="sm:h-[45%] sm:w-[12%] cursor-pointer flex justify-center items-center"
          >
            Connect{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
